import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connectInfiniteHits, connectStateResults } from 'react-instantsearch-dom';
import { Button, ArrowLink } from '@bwoty-web/styleguide';
import { AlgoliaHitItem } from './AlgoliaHit';
import FaqHit from './FaqHit';
import ResortHit from './ResortHit';
import HotelHit from './HotelHit';
import dataLayerPush from './dataLayer';

const propTypes = {
  hits: PropTypes.arrayOf(PropTypes.shape),
  resultLabel: PropTypes.string,
  type: PropTypes.string,
  changeTab: PropTypes.func,
  url: PropTypes.string,
  query: PropTypes.string,
  siteId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

const LoadMoreButton = connectStateResults(({ searching, hide, refine, label }) => {
  if (hide) {
    return null;
  }

  return (
    <div className="header-footer-algolia-result__show-more">
      <Button
        onClick={() => { refine(); }}
        rel="nofollow"
        target="_blank"
        loading={searching}
        disabled={searching}
        theme="primary"
      >
        {label}
      </Button>
    </div>
  );
});

const goToHit = (hit, e, query = '') => {
  e.preventDefault();
  const typeStr = hit.type.charAt(0).toUpperCase() + hit.type.slice(1);

  dataLayerPush({
    event: 'site_search_category',
    eventCategory: 'Site Search',
    eventAction: `Click ${typeStr} Link`,
    eventLabel: hit.url,
    siteSearchQuery: query,
  });

  window.location = hit.url;
};

const algoliaHitItemMobilePropTypes = {
  hit: PropTypes.shape({}),
  texts: PropTypes.shape({}),
  query: PropTypes.string,
  siteId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

const AlgoliaHitItemMobile = connectStateResults(({ hit, texts, searchState, siteId }) => {
  const query = searchState?.query || '';

  switch (hit.type) {
    case 'hotel':
      return <HotelHit siteId={siteId} hit={hit} texts={texts} onSelect={e => goToHit(hit, e, query)} />;
    case 'faq':
      return <FaqHit hit={hit} texts={texts} />;
    case 'area':
    case 'resort':
    case 'country':
    case 'umbraco':
    case 'region':
      return (
        <ResortHit
          hit={hit}
          texts={texts}
          onSelect={e => goToHit(hit, e, query)}
        />
      );
    default:
      return <AlgoliaHitItem hit={hit} />;
  }
});

AlgoliaHitItemMobile.propTypes = algoliaHitItemMobilePropTypes;

const AlgoliaHitItemsMobile = connectInfiniteHits(({
  hits,
  className,
  texts,
  hide,
  refine,
  hasMore,
  tabSelected = false,
  changeTab,
  siteId,
}) => {
  if (hide) {
    return null;
  } else if (!hits.length) {
    return (
      <span className="header-footer-algolia-result-link__no-results header-footer-algolia-result-link__no-results--selected">
        <div>{texts.notFoundLabel}</div>
        <div>{texts.refineSearch}</div>
        {tabSelected && <ArrowLink role="presentation" onClick={() => changeTab()}>{texts.showAllResult}</ArrowLink>}
      </span>
    );
  }

  return (
    <div className={className}>
      {hits.map((hit) => {
        return (
          <div
            key={hit.objectID}
            className={classNames(
              'header-footer-algolia-hit-container',
              { [`header-footer-algolia-hit-container--${hit.type}`]: hit.type }
            )}
          >
            <AlgoliaHitItemMobile
              key={hit.objectID}
              hit={hit}
              texts={texts}
              siteId={siteId}
            />
          </div>
        );
      })}
      <LoadMoreButton
        hide={!hasMore}
        refine={refine}
        label={texts.showMore}
      />
    </div>
  );
});

AlgoliaHitItemsMobile.propTypes = propTypes;

export default AlgoliaHitItemsMobile;
