export const getCloudinaryImageUrl = (imageDomain, hitType, image, format = 'jpg') => {
  const bothHeightAndWidth = image?.width > 0 && image?.height > 0;
  const x = Math.round(image?.focalPoint?.leftPixel) || Math.round(image?.width * 0.5);
  const y = Math.round(image?.focalPoint?.topPixel) || Math.round(image?.height * 0.61);
  const c = hitType === 'umbraco' ? `${bothHeightAndWidth ? `c_fill,g_xy_center,x_${x},y_${y}` : 'c_scale'}`
  : 'c_fill';

  return `${imageDomain}/image/upload/${c},${image?.width ? `w_${image?.width},` :
  ''}${image?.height ? `h_${image?.height},` : ''}f_${format},${image?.quality ?
    `q_${image?.quality}` : 'q_auto:eco'}${image?.relativeSrc || image?.url || image.mediaUrl}`;
};

const getCloudinaryImageSource = (imageDomain, hitType, image, type, media) => {
  return {
    media,
    srcSet: getCloudinaryImageUrl(imageDomain, hitType, image, type),
    type: `image/${type}`,
  };
};

export const getCloudinaryImageSources = (imageDomain, hitType, image, mediaQuery) => [
  getCloudinaryImageSource(imageDomain, hitType, image, 'avif', mediaQuery),
  getCloudinaryImageSource(imageDomain, hitType, image, 'jp2', mediaQuery),
  getCloudinaryImageSource(imageDomain, hitType, image, 'webp', mediaQuery),
  getCloudinaryImageSource(imageDomain, hitType, image, 'jpg', mediaQuery),
];
