import { connectHits, connectStateResults } from 'react-instantsearch-dom';
import PropTypes from 'prop-types';
import {
  ArrowLink,
  Picture,
  Icon,
  USER_INTERFACE_INFORMATION,
  DOCUMENT_MEDIA_PHOTO,
  LoadBlock
} from '@bwoty-web/styleguide';
import ShowMore from './_ShowMore';
import dataLayerPush from './dataLayer';
import { getCloudinaryImageSources } from '../../../utils/cloudinary';
import { getCloudinaryImageSources as getCloudinaryImageSourcesFromTemplate } from '../../../utils/cloudinaryUriTemplate';
import './AlgoliaHit.scss';

const propTypes = {
  hit: PropTypes.shape({}),
  query: PropTypes.string,
};

const defaultProps = {
  hit: {},
  query: '',
};

const goToHit = (url, type = '', query = '') => {
  const typeStr = type.charAt(0).toUpperCase() + type.slice(1);

  dataLayerPush({
    event: 'site_search_category',
    eventCategory: 'Site Search',
    eventAction: `Click ${typeStr} Link`,
    eventLabel: url,
    siteSearchQuery: query,
  });
  window.location = url;
};

export const AlgoliaHitItem = connectStateResults(({ hit, searchState, imageDomain }) => {
  let imageSources = hit?.cloudinaryUriTemplate ?
    getCloudinaryImageSourcesFromTemplate({ cloudinaryUriTemplate: hit?.cloudinaryUriTemplate, width: 40, height: 40 }) : [];
  
  if (!imageSources || imageSources.length < 1) {
    imageSources = hit?.imageRelativeUrl ?
      getCloudinaryImageSources(imageDomain, hit?.type, { relativeSrc: hit?.imageRelativeUrl, width: 40, height: 40 }) : [];
  }

  return (
    <button
      type="button"
      className="header-footer-algolia-hit"
      onClick={() => goToHit(hit.url, hit.type, (searchState?.query || ''))}
    >
      {hit.type === 'faq' ? (
        <Icon
          className="header-footer-algolia-hit__icon"
          path={USER_INTERFACE_INFORMATION}
          size="s"
        />
      ) : (
        <div className="header-footer-algolia-hit__image">
          <Picture
            className="header-footer-algolia-hit__image-src"
            sources={imageSources}
            alt={hit.name}
            lazy
            errorIcon={{
              path: DOCUMENT_MEDIA_PHOTO,
              size: 's',
              sizeMobile: 's',
            }}
          />
        </div>
      )}
      <div className="header-footer-algolia-hit__content">
        <div className="header-footer-algolia-hit__content-name">{(hit.type === 'faq' || hit.type === 'umbraco') ? hit.title : hit.name}</div>
        {hit.type !== 'faq' && hit.type !== 'umbraco' && (
          <div className="header-footer-algolia-hit__content-geographical">
            {hit.area && <div className="header-footer-algolia-hit__content-geographical-item">{hit.area}</div>}
            {hit.country && <div className="header-footer-algolia-hit__content-geographical-item">{hit.country}</div>}
          </div>
        )}
      </div>
    </button>
  );
});

AlgoliaHitItem.propTypes = propTypes;
AlgoliaHitItem.defaultProps = defaultProps;

const AlgoliaHitStalled = () => {
  return (
    <div className="header-footer-algolia-hit header-footer-algolia-hit--loading">
      <div className="header-footer-algolia-hit__image">
        <LoadBlock style={{ width: '100%', height: '35px' }} />
      </div>
      <div className="header-footer-algolia-hit__content">
        <div className="header-footer-algolia-hit__content-name" style={{ marginBottom: '5px' }}>
          <LoadBlock style={{ width: '100%', height: '20px' }} />
        </div>
        <div className="header-footer-algolia-hit__content-geographical">
          <LoadBlock style={{ width: '100%', height: '10px' }} />
        </div>
      </div>
    </div>
  );
};

const AlgoliaHit = connectHits(({
  hits,
  className,
  type,
  resultLabel = '',
  tabSelected,
  hide,
  changeTab,
  texts,
  isSearchStalled,
  imageDomain,
}) => {
  if (hide) {
    return null;
  } else if (!hits.length && !isSearchStalled) {
    const messageSplit = resultLabel.split(/{|}/);
    const noResultText = `${messageSplit[0]} ${messageSplit[2]}`;

    return (
      <span className="header-footer-algolia-result-link__no-results">
        <div><i>{texts.notFoundLabel}</i></div>
        {texts.noResultLink[type] && (
          <div className="header-footer-algolia-result-link__no-results-link">
            <ArrowLink href={texts.noResultLink[type]}>{noResultText}</ArrowLink>
          </div>
        )}
      </span>
    );
  }

  if (isSearchStalled) {
    return (
      <div className={className}>
        <AlgoliaHitStalled />
      </div>
    );
  }

  return (
    <div className={className}>
      {hits.map(hit => <AlgoliaHitItem key={hit.objectID} hit={hit} imageDomain={imageDomain} />)}
      <ShowMore
        url={texts.resultUrl}
        type={type}
        resultLabel={resultLabel}
        hide={tabSelected}
        changeTab={changeTab}
      />
    </div>
  );
});

export default AlgoliaHit;
