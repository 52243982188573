// This file contains functions that build image sources for the new image format from ContentData
// The new format contains cloudinaryUriTemplate instead of relativeSrc.

import UrlTemplate from 'url-template';

const getGravity = (image) => {
  if (!image) {
    return '';
  }

  const { size, focalPoint } = image;
  const { height, width } = size || {};
  const hasFocalPoint = focalPoint?.leftPixel && focalPoint?.topPixel;
  const hasBothHeightAndWidth = width > 0 && height > 0;

  let gravity = 'g_auto,';
  if (hasFocalPoint) {
    const focalX = Math.round(focalPoint?.leftPixel);
    const focalY = Math.round(focalPoint?.topPixel);
    gravity = `g_xy_center,x_${focalX},y_${focalY},`;
  } else if (hasBothHeightAndWidth) {
    const focalX = Math.round(width * 0.5);
    const focalY = Math.round(height * 0.61);
    gravity = `g_xy_center,x_${focalX},y_${focalY},`;
  }

  return gravity;
};

const convertToUrl = (cloudinaryUriTemplate, transform) => {
  const paramValues = {};
  paramValues.transform = transform;
  const template = UrlTemplate.parse(cloudinaryUriTemplate);
  return template.expand(paramValues);
};

const getCloudinaryImageUrl = (image, format = 'jpg') => {
  if (!image || !image.cloudinaryUriTemplate) {
    return null;
  }

  const { cloudinaryUriTemplate } = image;
  const width = image?.width;
  const height = image?.height;
  const gravity = getGravity(image);

  // Set quality
  const quality = image?.quality ? `q_${image?.quality}` : 'q_auto:eco';

  const cloudinaryUrl = convertToUrl(
    cloudinaryUriTemplate,
    `c_fill,${gravity}${width ? `w_${width},` : ''}${
      height ? `h_${height},` : ''
    }f_${format},${quality}/`,
  );

  return cloudinaryUrl;
};

export const getCloudinaryImageSources = (image, mediaQuery) => {
  const imageTypes = ['avif', 'jp2', 'webp', 'jpg'];
  const imageSources = imageTypes.map((imageType) => {
    return {
      media: mediaQuery,
      srcSet: getCloudinaryImageUrl(image, imageType),
      type: `image/${imageType}`,
    };
  });

  return imageSources;
};
