import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Index, connectStateResults } from 'react-instantsearch-dom';
import { LoadBlock } from '@bwoty-web/styleguide';
import AlgoliaHit from './AlgoliaHit';
import AlgoliaHitMobile from './AlgoliaHitMobile';
import AlgoliaHeader from './AlgoliaHeader';

const propTypes = {
  index: PropTypes.shape({}),
  texts: PropTypes.shape({}),
  selectedTab: PropTypes.string,
  changeTab: PropTypes.func,
  isSearchStalled: PropTypes.bool,
  searchState: PropTypes.object,
  siteId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

const defaultProps = {
  index: {},
  texts: {},
  selectedTab: '',
  changeTab: () => {},
  isSearchStalled: false,
  searchState: {},
};

const AlgoliaIndex = ({ siteId, index, selectedTab, texts, changeTab, isSearchStalled, searchState, imageDomain }) => {
  if (!index) { return false; }

  const notSelected = selectedTab && selectedTab !== index.key;
  const HitComponent = selectedTab ? AlgoliaHitMobile : AlgoliaHit;
  const query = searchState?.query || '';

  const content = (
    <>
      {isSearchStalled ?
        <div className="header-footer-algolia-result__row-column-label">
          <LoadBlock style={{ width: '100%', height: '20px' }} />
        </div> :
        <AlgoliaHeader
          key={`${index.key}_header`}
          type={index.key}
          header={index.title}
          hide={notSelected || !!selectedTab}
          resultUrl={texts.resultUrl}
          changeTab={changeTab}
          query={query}
        />
      }
      <HitComponent
        siteId={siteId}
        imageDomain={imageDomain}
        key={`${index.key}_component`}
        type={index.key}
        className="header-footer-algolia-result__row-column-data"
        texts={texts}
        resultLabel={index.resultLabel}
        hide={notSelected}
        tabSelected={!!selectedTab}
        changeTab={changeTab}
        isSearchStalled={isSearchStalled}
      />
    </>
  );

  return (
    <div
      key={index.value}
      className={classNames(
        'header-footer-algolia-result__row-column',
        {
          'header-footer-algolia-result__row-column--colored-background': selectedTab && selectedTab !== 'faq',
          'header-footer-algolia-result__row-column--hidden': selectedTab && selectedTab !== index.key,
          [`header-footer-algolia-result__row-column--${index.key}`]: selectedTab,
        },
      )}
    >
      <Index indexName={index.value}>
        {content}
      </Index>
    </div>
  );
};

AlgoliaIndex.propTypes = propTypes;
AlgoliaIndex.defaultProps = defaultProps;

export default connectStateResults(AlgoliaIndex);
