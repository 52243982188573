/* eslint-disable react/no-danger */
import { useState } from 'react';
import PropTypes from 'prop-types';
import className from 'classnames';
import {
  USER_INTERFACE_ARROW_04_UP,
  USER_INTERFACE_ARROW_04_DOWN,
  Icon,
} from '@bwoty-web/styleguide';

import './FaqHit.scss';

const FaqHit = ({ hit }) => {
  const [open, setOpen] = useState(false);

  return (
    <button
      className={className('header-footer-algolia-faq-hit', {
        'header-footer-algolia-faq-hit--expanded': open,
      })}
      onClick={() => setOpen(!open)}
      type="button"
    >
      <div className="header-footer-algolia-faq-hit__content">
        <div className="header-footer-algolia-faq-hit__content-title">
          {hit.title}
        </div>
        <Icon
          className="header-footer-algolia-faq-hit__content-icon"
          path={
            open ? USER_INTERFACE_ARROW_04_UP : USER_INTERFACE_ARROW_04_DOWN
          }
          size="xxs"
        />
      </div>
      {open && (
        <div
          className="header-footer-algolia-faq-hit__answer"
          dangerouslySetInnerHTML={{ __html: hit.content }}
        />
      )}
    </button>
  );
};

FaqHit.propTypes = {
  hit: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.string,
  }),
};

FaqHit.defaultProps = {
  hit: {},
};

export default FaqHit;
