import PropTypes from 'prop-types';
import {
  Picture,
  WaveRating,
  GuestRating,
  Tags,
  ConceptBadge,
  Icon,
  DOCUMENT_MEDIA_PHOTO,
  USER_INTERFACE_ARROW_04_RIGHT
} from '@bwoty-web/styleguide';
import classNames from 'classnames';
import { getCloudinaryImageSources } from '../../../utils/cloudinaryUriTemplate';

import './HotelHit.scss';

const noSpacingConcepts = [
  'familycomfort',
  'primeselect',
  'primecomfort',
];

const mapImageSources = (cloudinaryUriTemplate) => {
  if (!cloudinaryUriTemplate) { return []; }

  return [
    ...getCloudinaryImageSources({ cloudinaryUriTemplate, width: 172, height: 172 }, '(max-width: 767px)'),
    ...getCloudinaryImageSources({ cloudinaryUriTemplate, width: 340, height: 340 }, '(min-width: 768px)'),
  ];
};

const HotelHit = ({ hit, onSelect, texts, siteId }) => {
  if (!hit) {
    return null;
  }

  const tags = [
    (hit.isAdultHotel ? { text: texts.adultFriendly } : null),
    (hit.isFamilyHotel ? { text: texts.childFriendly } : null),
  ].filter(obj => !!obj);

  const geographicalString = `${hit.resort}${hit.area ? `, ${hit.area}` : ''}`;

  const conceptBadgeClassnames = classNames(
    'algolia-hotel-hit-image-panel__image-content',
    { 'algolia-hotel-hit-image-panel__image-content--nospacing': noSpacingConcepts.includes(hit.conceptImageClass) },
  );

  return (
    <div className="algolia-hotel-hit">
      <div className="algolia-hotel-hit-image-panel">
        <Picture
          sources={mapImageSources(hit.cloudinaryUriTemplate)}
          alt={hit.name}
          errorIcon={{
            path: DOCUMENT_MEDIA_PHOTO,
            size: 'm',
            sizeMobile: 'm',
          }}
          lazy
        />
        <div className={conceptBadgeClassnames}>
          <ConceptBadge
            src={hit.conceptImage}
            concept={hit.conceptImageClass}
            siteId={siteId}
          />
          <Tags
            className="algolia-hotel-hit-image-panel__tags"
            tags={tags}
            responsive
          />
        </div>
      </div>
      <div className="algolia-hotel-hit-content-panel">
        <div className="algolia-hotel-hit-content-panel__header">
          <h3 className="algolia-hotel-hit-content-panel__hotel-name">
            {hit.name}
          </h3>
          <div className="algolia-hotel-hit-content-panel__geographical">
            {geographicalString}
          </div>
          <WaveRating
            rating={(hit?.rating || 1) * 2}
            responsive
          />
        </div>
        <div className="algolia-hotel-hit-content-panel__body">
          <GuestRating
            rating={hit.guestRating > 1 ? hit.guestRating : null}
            responsive
          />
        </div>
        <div className="algolia-hotel-hit-content-panel__footer">
          <Icon
            className="algolia-hotel-hit__arrow"
            path={USER_INTERFACE_ARROW_04_RIGHT}
            size="xs"
          />
        </div>
      </div>
      {(hit.url || onSelect) && (
          <a
            className="algolia-hotel-hit__link"
            onClick={onSelect}
            href={hit.url}
          />
        )}
    </div>
  );
};

HotelHit.propTypes = {
  hit: PropTypes.shape({}).isRequired,
  onSelect: PropTypes.func.isRequired,
  texts: PropTypes.shape({}).isRequired,
  siteId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  imageDomain: PropTypes.string.isRequired,
};

export default HotelHit;
