import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import algoliasearch from 'algoliasearch/lite';
import classNames from 'classnames';
import smoothscroll from 'smoothscroll-polyfill';
import { InstantSearch, Configure } from 'react-instantsearch-dom';
import AlgoliaSearchButton from './AlgoliaSearchButton';
import AlgoliaSearchBox from './AlgoliaSearchBox';
import AlgoliaSearchMenu from './AlgoliaSearchMenu';
import AlgoliaIndex from './AlgoliaIndex';

import './AlgoliaSearch.scss';

const TABLET_MAX_WIDTH = 768;
const TABLET_MAX_HEIGHT = 1024;
const ALGOLIA_SEARCH_BOX_ID = 'searchInput';

const MUCD_CONVERTER = {
  vs: 'VS',
  vn: 'VN',
  sd: 'SD',
  tf: 'TF',
  ds: 'DS',
  1: 'VS',
  3: 'VN',
  11: 'SD',
  15: 'TF',
  18: 'DS',
};

// let firstLoad = true;
let searchClient = undefined;

const getSearchClient = (appId, apiKey) => {
  if (searchClient) {
    return searchClient;
  }

  const client = algoliasearch(appId, apiKey);

  searchClient = {
    search(requests) {
      const textInput = document.getElementById(ALGOLIA_SEARCH_BOX_ID);
      const textInputLength = textInput?.value?.length || 0;

      if (textInputLength > 0) {
        if (requests.some((r) => (r?.params?.query?.length || 0) === 0)) {
          return;
        }
      }

      return client.search(requests);
    },
  };

  return searchClient;
};

const AlgoliaSearch = ({
  open,
  siteId,
  config,
  texts,
  className,
  onChange,
  onClick,
  imageDomain,
}) => {
  const [selectedTab, setSelectedTab] = useState('');
  const containerRef = useRef(null);

  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  const onChangeTab = (tab) => {
    setSelectedTab(tab);

    if (containerRef.current) {
      containerRef.current.scrollTo(0, 0);
    }

    setTimeout(() => {
      const selectedTabNode = document.querySelector(
        '.header-footer-algolia-search-menu-tab--selected',
      );
      if (selectedTabNode) {
        selectedTabNode.scrollIntoView({ inline: 'start', behavior: 'smooth' });
      }
    }, 10);
  };

  const isMobile = () => {
    if (!open) {
      return false;
    }

    return (
      window &&
      window.innerWidth < TABLET_MAX_WIDTH &&
      window.innerHeight < TABLET_MAX_HEIGHT
    );
  };

  const popupClick = (e) => {
    e.stopPropagation();
  };

  const getMarketUnit = () => {
    return MUCD_CONVERTER[siteId];
  };

  const getIndexNames = () => {
    const marketUnit = getMarketUnit();

    return {
      destination: config.destinationIndex && {
        title: texts.destinationHeader,
        value: `${config.destinationIndex}_${marketUnit}`,
        resultLabel: texts.resultLabels.destination,
        key: 'destination',
      },
      hotel: config.hotelIndex && {
        title: texts.hotelHeader,
        value: `${config.hotelIndex}_${marketUnit}`,
        resultLabel: texts.resultLabels.hotel,
        key: 'hotel',
      },
      faq: config.faqIndex && {
        title: texts.faqHeader,
        value: `${config.faqIndex}_${marketUnit}`,
        resultLabel: texts.resultLabels.faq,
        key: 'faq',
      },
      page: config.pageIndex && {
        title: texts.pageHeader,
        value: `${config.pageIndex}_${marketUnit}`,
        resultLabel: texts.resultLabels.page,
        key: 'page',
      },
    };
  };

  const marketUnit = getMarketUnit();

  if (!config || !config.appId) {
    console.log('Missing algolia config');
    return null;
  }

  if (!marketUnit) {
    console.log(
      `Missing mucd. Must be one of: ${Object.keys(MUCD_CONVERTER).join(',')}`,
    );
    return null;
  }

  const indexNames = getIndexNames();
  const nrOfIndex = Object.keys(indexNames).filter((k) => indexNames[k]).length;
  const hitsPerPage = selectedTab ? 10 : isMobile() ? 2 : 3;

  const classes = classNames(className, 'header-footer-algolia-search', {
    'header-footer-algolia-search--open': open,
  });

  const algoliaSearchMenuClasses = classNames(
    'header-footer-algolia-result',
    `header-footer-algolia-result--${nrOfIndex}-columns`,
  );

  return (
    <div className={classes} ref={containerRef}>
      <InstantSearch
        searchClient={getSearchClient(config.appId, config.apiKey)}
        indexName={indexNames.destination.value}
        stalledSearchDelay={1000}
      >
        <div
          className="header-footer-algolia-search__content"
          role="presentation"
          onClick={popupClick}
        >
          {config.withoutPreInputBox && !open ? (
            <AlgoliaSearchButton onClick={() => onClick(true)} searchLabel={texts.searchButtonLabel} />
          ):(
            <AlgoliaSearchBox
              id={ALGOLIA_SEARCH_BOX_ID}
              placeholder={texts.placeholder}
              onCancel={() => onClick(false)}
              cancelText={texts.cancelLabel}
              onClick={() => onClick(true)}
              onChange={onChange}
              isOpen={open}
            />
          )}
          <Configure hitsPerPage={hitsPerPage} />
          {open && (
            <AlgoliaSearchMenu
              changeTab={onChangeTab}
              selectedTab={selectedTab}
              indexNames={indexNames}
              header={texts.tabAllHeader}
            />
          )}
          <div className={algoliaSearchMenuClasses}>
            <div className="header-footer-algolia-result__row">
              <AlgoliaIndex
                siteId={siteId}
                imageDomain={imageDomain}
                index={indexNames.destination}
                selectedTab={selectedTab}
                texts={texts}
                changeTab={onChangeTab}
              />
              <AlgoliaIndex
                siteId={siteId}
                imageDomain={imageDomain}
                index={indexNames.hotel}
                selectedTab={selectedTab}
                texts={texts}
                changeTab={onChangeTab}
              />
            </div>
            {(config.faqIndex || config.pageIndex) && (
              <div className="header-footer-algolia-result__row">
                <AlgoliaIndex
                  siteId={siteId}
                  imageDomain={imageDomain}
                  index={indexNames.faq}
                  selectedTab={selectedTab}
                  texts={texts}
                  changeTab={onChangeTab}
                />
                <AlgoliaIndex
                  siteId={siteId}
                  imageDomain={imageDomain}
                  index={indexNames.page}
                  selectedTab={selectedTab}
                  texts={texts}
                  changeTab={onChangeTab}
                />
              </div>
            )}
          </div>
        </div>
      </InstantSearch>
    </div>
  );
};

AlgoliaSearch.propTypes = {
  mucd: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  imageDomain: PropTypes.string,
  siteId: PropTypes.string,
  texts: PropTypes.shape({
    resultUrl: PropTypes.string,
    noResultUrl: PropTypes.shape({
      hotel: PropTypes.string,
      faq: PropTypes.string,
      destination: PropTypes.string,
    }),
    placeholder: PropTypes.string,
    destinationHeader: PropTypes.string,
    hotelHeader: PropTypes.string,
    faqHeader: PropTypes.string,
    pageHeader: PropTypes.string,
    notFoundLabel: PropTypes.string,
    cancelLabel: PropTypes.string,
    resultLabels: PropTypes.shape({
      hotel: PropTypes.string,
      faq: PropTypes.string,
      destination: PropTypes.string,
      page: PropTypes.string,
    }),
    showMore: PropTypes.string,
    guestRatingResultLabel: PropTypes.string,
    hotelResultLabel: PropTypes.string,
    tabAllHeader: PropTypes.string,
    adultFriendly: PropTypes.string,
    childFriendly: PropTypes.string,
    searchButtonLabel: PropTypes.string,
  }),
  config: PropTypes.shape({
    appId: PropTypes.string.isRequired,
    apiKey: PropTypes.string.isRequired,
    hotelIndex: PropTypes.string.isRequired,
    destinationIndex: PropTypes.string.isRequired,
    faqIndex: PropTypes.string.isRequired,
    pageIndex: PropTypes.string.isRequired,
    withoutPreInputBox: PropTypes.bool,
  }),
  className: PropTypes.string,
  open: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
};

AlgoliaSearch.defaultProps = {
  texts: {},
  siteId: '1',
  config: {},
  open: false,
  className: '',
  onChange: () => {},
  onClick: () => {},
};

export default AlgoliaSearch;
