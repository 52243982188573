import PropTypes from 'prop-types';
import { connectStateResults } from 'react-instantsearch-dom';
import AlgoliaSearchMenuTab from './AlgoliaSearchMenuTab';
import connectAnalytics from './analyticsConnector';

import './AlgoliaSearchMenu.scss';

const propTypes = {
  indexNames: PropTypes.shape({}),
  changeTab: PropTypes.func,
  selectedTab: PropTypes.string,
  allSearchResults: PropTypes.shape({}),
  header: PropTypes.string,
};

const AlgoliaSearchMenu = connectStateResults(({ indexNames, changeTab, selectedTab, allSearchResults, header }) => {
  return (
    <div className="header-footer-algolia-search-menu">
      <AlgoliaSearchMenuTab onClick={() => changeTab(null)} selected={!selectedTab}>{header}</AlgoliaSearchMenuTab>
      {Object.keys(indexNames).filter(key => indexNames[key]).map((indexKey) => {
        const index = indexNames[indexKey];

        let nrOfHits = 0;
        if (allSearchResults) {
          nrOfHits = allSearchResults[`${index?.value}`]?.nbHits || 0;
        }
        return (
          <AlgoliaSearchMenuTab
            key={index.key}
            onClick={() => changeTab(index.key)}
            selected={selectedTab === index.key}
            disabled={!nrOfHits}
          >
            {index.title} ({nrOfHits})
          </AlgoliaSearchMenuTab>
        );
      })}
    </div>
  );
});

AlgoliaSearchMenu.propTypes = propTypes;

export default connectAnalytics(AlgoliaSearchMenu);
