/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';
import { USER_INTERFACE_SEARCH, Icon } from '@bwoty-web/styleguide';
import './AlgoliaSearchButton.scss';

const AlgoliaSearchButton = ({ searchLabel, onClick }) => {

  const onKeyDown = (e) => {
    if (e.which === 9) {
      const menuMainDiv = document.getElementById('header-hamburger');
      if (menuMainDiv.classList.contains('header-app-hamburger__bg-white')) {
        e.preventDefault();
        if (e.shiftKey) 
          document.getElementById('header-hamburger-navigation-close-button').focus();
        else if (menuMainDiv.children[0].children.length > 0)
          menuMainDiv.children[0].children[0].children[0].focus();
      }
    }
  }

  return (
    <div className='algolia-search_div'>
      <button
        type="button"
        className="algolia-search__button"
        id="header-hamburger-search-button"
        onClick={onClick}
        onKeyDown={onKeyDown}
      >
        <Icon
          path={USER_INTERFACE_SEARCH}
          size="s"
          sizeMobile="xx"
          className="header-hamburger-icon"
          title="Menu"
        />
        {searchLabel && <span>{searchLabel}</span>}
      </button>
      <script
        dangerouslySetInnerHTML={{
          __html: `
            const tabOnSearchAlgoliaButtonHandler = (e) => {
              if (e.which === 9) {
                const menuMainDiv = document.getElementById('header-hamburger');
                if (menuMainDiv.classList.contains('header-app-hamburger__bg-white')) {
                  e.preventDefault();
                  if (e.shiftKey) 
                    document.getElementById('header-hamburger-navigation-close-button').focus();
                  else if (menuMainDiv.children[0].children.length > 0)
                    menuMainDiv.children[0].children[0].children[0].focus();
                }
              }
            }

            document.getElementById('header-hamburger-search-button').addEventListener("keydown", tabOnSearchAlgoliaButtonHandler);

          `,
        }}
      />
    </div>
  );
};

AlgoliaSearchButton.defaultProps = {
  searchLabel: '',
  onClick: null,  
};

AlgoliaSearchButton.propTypes = {
  searchLabel: PropTypes.string,
  onClick: PropTypes.func,
};

export default AlgoliaSearchButton;
