import PropTypes from 'prop-types';
import {
  Picture,
  Icon,
  GuestRating,
  USER_INTERFACE_ARROW_04_RIGHT,
  DOCUMENT_MEDIA_PHOTO
} from '@bwoty-web/styleguide';
import { getCloudinaryImageSources } from '../../../utils/cloudinaryUriTemplate';

import './ResortHit.scss';

const getPreamble = (hit) => {
  switch (hit.type) {
    case 'resort':
      return `${hit.area ? `${hit.area}, ` : ''}${hit.country || ''}`;
    case 'area':
      return hit.country || '';
    default:
      return '';
  }
};

const ResortHit = ({ hit, texts, onSelect }) => {
  if (!hit) {
    return null;
  }

  const { url, cloudinaryUriTemplate, name } = hit;
  const imageSources = cloudinaryUriTemplate ? [
    ...getCloudinaryImageSources({ cloudinaryUriTemplate, height: 116 }, '(max-width: 767px)'),
    ...getCloudinaryImageSources({ cloudinaryUriTemplate, width: 380, height: 228 }, '(min-width: 768px)')
  ] : [];

  const title = hit.name || hit.title;
  const preamble = getPreamble(hit);

  return (
    <div className="algolia-resort-hit" type="button">
      <div className="algolia-resort-hit__picture">
        <Picture
          sources={imageSources}
          errorIcon={{
            path: DOCUMENT_MEDIA_PHOTO,
            sizeMobile: 'm',
          }}
          alt={name || title}
          lazy
        />
      </div>
      <div className="algolia-resort-hit__content-container">
        <div className="algolia-resort-hit__content">
          <div className="algolia-resort-hit__content-top">
            <h2 className="algolia-resort-hit__content-title">{title}</h2>
            <p className="algolia-resort-hit__content-preamble">{preamble}</p>
          </div>
          <div className="algolia-resort-hit__content-bottom">
            { !isNaN(hit.rating) && (
              <div className="algolia-resort-hit__content-counter">
                <GuestRating
                  rating={hit.rating}
                  responsive
                />
                <div className="algolia-resort-hit__content-counter-label">
                  {texts.guestRatingResultLabel}
                </div>
              </div>
            )}
            { !isNaN(hit.noHotels) && (
              <div className="algolia-resort-hit__content-counter">
                <div className="algolia-resort-hit__content-counter-hotels">{hit.noHotels}</div>
                <div className="algolia-resort-hit__content-counter-label">
                  {texts.hotelResultLabel}
                </div>
              </div>
            )}
          </div>
        </div>
        { (url || onSelect) && (
          <div className="algolia-resort-hit__arrow-container">
            <Icon
              className="algolia-resort-hit__arrow"
              path={USER_INTERFACE_ARROW_04_RIGHT}
              size="xs"
            />
          </div>
        )}
      </div>
      {(url || onSelect) && (
        /* eslint-disable jsx-a11y/anchor-has-content */
        <a
          className="algolia-resort-hit__link"
          onClick={onSelect}
          href={url}
        />
      )}
    </div>
  );
};

ResortHit.propTypes = {
  hit: PropTypes.shape({
    imageRelativeUrl: PropTypes.string,
  }).isRequired,
  texts: PropTypes.shape({}).isRequired,
  onSelect: PropTypes.func.isRequired,
  imageDomain: PropTypes.string.isRequired,
};

export default ResortHit;
