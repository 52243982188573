import classNames from 'classnames';
import PropTypes from 'prop-types';

import './AlgoliaSearchMenuTab.scss';

const propTypes = {
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node,
};

const defaultProps = {
  selected: false,
  disabled: false,
  children: null,
};

const AlgoliaSearchMenuTab = ({ selected, disabled, children, ...rest }) => {
  if (!children) { return false; }

  return (
    <button
      className={classNames(
        'header-footer-algolia-search-menu-tab',
        { 'header-footer-algolia-search-menu-tab--selected': selected },
        { 'header-footer-algolia-search-menu-tab--disabled': disabled }
      )}
      type="button"
      {...rest}
    >
      {children}
    </button>
  );
};

AlgoliaSearchMenuTab.propTypes = propTypes;
AlgoliaSearchMenuTab.defaultProps = defaultProps;

export default AlgoliaSearchMenuTab;
