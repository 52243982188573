import { connectStats, connectStateResults } from 'react-instantsearch-dom';
import { ArrowLink } from '@bwoty-web/styleguide';
import dataLayerPush from './dataLayer';

import './_ShowMore.scss';

const TABLET_MAX_WIDTH = 768;
const TABLET_MAX_HEIGHT = 1024;

const goToResultPage = (e, url, changeTab, type, query = '') => {
  e.preventDefault();
  dataLayerPush({
    event: 'site_search_show_more',
    eventCategory: 'Site Search',
    eventAction: `Click ${type[0].toUpperCase() + type.slice(1)} Show more`,
    eventLabel: 'None',
    siteSearchQuery: query,
  });

  if (window.innerWidth < TABLET_MAX_WIDTH && window.innerHeight < TABLET_MAX_HEIGHT) {
    return changeTab(type);
  }

  window.location = `${url}?section=${type}&q=${encodeURIComponent(query)}`;
  return false;
};

const ShowMore = connectStateResults(connectStats(({
  nbHits,
  url,
  changeTab,
  type,
  resultLabel = '',
  hide,
  searchState,
}) => {
  if (hide) {
    return null;
  }

  const query = searchState?.query || '';
  const messageSplit = resultLabel.split(/{|}/);
  const resultText = messageSplit[0] + nbHits + messageSplit[2];
  const nrOfHitsShowed = (window.innerWidth < TABLET_MAX_WIDTH && window.innerHeight < TABLET_MAX_HEIGHT) ? 2 : 3;

  if (!nbHits || (nbHits <= nrOfHitsShowed)) { return false; }

  return (
    <div className="header-footer-algolia-result-link">
      <ArrowLink onClick={e => goToResultPage(e, url, changeTab, type, query)} alt="show-more">{resultText}</ArrowLink>
    </div>
  );
}));

export default ShowMore;
