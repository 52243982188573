import { createConnector } from 'react-instantsearch-dom';
import dataLayerPush from './dataLayer';

const debounce = (callback, time = 250, interval) =>
  (...args) => {
    clearTimeout(interval);
    interval = setTimeout(() => callback(...args), time); // eslint-disable-line no-param-reassign
  };

let lastQuery = '';

const logToGA = debounce((data, search) => {
  if (!search || !search.query || !data || !data.results || search.query === lastQuery) { return false; }

  lastQuery = search.query;

  Object.keys(data.results || {}).forEach((indexName) => {
    if (!data.results[indexName].nbHits) {
      let type = 'Hotels';
      if (indexName.indexOf('DESTINATIONS') > -1) {
        type = 'Destinations';
      } else if (indexName.indexOf('FAQ') > -1) {
        type = 'Faq';
      } else if (indexName.indexOf('UMBRACO') > -1) {
        type = 'Page';
      }
      dataLayerPush({
        event: 'site_search_no_hits',
        eventCategory: 'Site Search',
        eventAction: '0-hits',
        eventLabel: type,
        siteSearchQuery: search.query,
      });
    }
  });
}, 1000);

const connectAnalytics = createConnector({
  displayName: 'AlgoliaAnalytics',

  getProvidedProps(props, searchState, searchResults) {
    // inspect search results here and report to GTM
    logToGA(searchResults, searchState);
    return props;
  },
});

export default connectAnalytics;
